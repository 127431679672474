export const postFunc = (api, params, onSuccess, ifErr, finalFunc, enable_token) => {
    const endpoint_url = process.env.REACT_APP_ENDPOINT_URL + api;

    const headers = {
        'Content-Type': 'application/json'
    }

    if (enable_token) {
        const token = localStorage.getItem("token");
        headers['Authorization'] = `Bearer ${token}`;
    }

    fetch(endpoint_url, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: headers
    })
        .then((response) => response.json())
        .then((data) => onSuccess(data))
        .catch((error) => {
            // 处理错误逻辑
            ifErr(error);
        }).finally(
        finalFunc()
    )
}


export const getFunc = (api, onSuccess, ifErr, finalFunc, enable_token) => {
    const endpoint_url = process.env.REACT_APP_ENDPOINT_URL + api;

    const headers = {
        'Content-Type': 'application/json'
    };

    if (enable_token) {
        const token = localStorage.getItem("token");
        headers['Authorization'] = `Bearer ${token}`;
    }

    fetch(endpoint_url, {
        method: 'GET',
        headers: headers
    })
        .then((response) => response.json())
        .then((data) => onSuccess(data))
        .catch((error) => {
            // 处理错误逻辑
            ifErr(error);
        })
        .finally(() => {
            finalFunc();
        });
};
