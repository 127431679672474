import React, { useEffect, useState } from 'react';
import { postFunc } from '../util/httpclient';
import {useNavigate} from "react-router-dom";



const BillsPage = () => {
    const [bills, setBills] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [billsPerPage, setBillsPerPage] = useState(10);
    const [balanceAmount, setBalanceAmount] = useState(0);
    const navigate = useNavigate();


    useEffect(() => {
        postFunc('/bills/list', { per_page: billsPerPage, page: currentPage }, (data) => {
            if (data.ret === 200) {
                setBills(data.bills);
            } else {
                alert(data.err);
            }
        }, (error) => {
            console.log(error);
            alert("Get bills list failed");
        }, () => {}, true);
    }, [currentPage, billsPerPage]);

    useEffect(() => {
        const user_id = localStorage.getItem("user_id");
        postFunc('/user/balance', {user_id}, (data) => {
            if (data.ret === 200) {
                setBalanceAmount(data.balance);
            } else {
                alert(data.err);

            }}, (error) => {
            console.log(error);
            alert("Get bills list failed");
        }, () => {}, true);
    }, []);

    const handleBillsPerPageChange = (event) => {
        setBillsPerPage(event.target.value);
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-orange-50">

            <div className="w-full md:w-2/3 lg:w-1/2 xl:w-1/3">
                <div className="flex" >
                    <div className="bg-orange-200 border-t-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md mb-4" role="alert">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center">
                                <svg className="fill-current h-6 w-6 text-orange-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M2 10a8 8 0 018-8 7.99 7.99 0 018 8 8 8 0 01-8 8 8 8 0 01-8-8zm8-6a6 6 0 00-6 6 6 6 0 006 6 6 6 0 006-6 6 6 0 00-6-6zm-.6 3.6a.6.6 0 111.2 0v3a.6.6 0 01-1.2 0v-3zm.6 6a1 1 0 110-2 1 1 0 010 2z"/>
                                </svg>
                                <div>
                                    <p className="font-bold">Please Note</p>
                                    <p className="text-sm">This page only shows the bills from the past month.</p>
                                </div>
                            </div>
                            <div className="text-right">
                                <p className="font-bold text-sm">Remaining Balance:</p>
                                <p className="text-sm">{balanceAmount} £</p>
                            </div>
                        </div>
                    </div>

                </div>

                <table className="table-auto w-full">
                    <thead>
                    <tr className="bg-orange-200 text-gray-600 uppercase text-sm leading-normal">
                        <th className="py-3 px-6 text-center">Transaction</th>
                        <th className="py-3 px-6 text-center">Amount</th>
                        <th className="py-3 px-6 text-center">Transaction Time</th>
                        <th className="py-3 px-6 text-center">Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    {bills.map((bill, index) => (
                        <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-orange-100"}>
                            <td className="py-3 px-6 text-center whitespace-nowrap">
                                {bill.transaction_type === 'charge' ? 'Charge' : 'Consume'}
                            </td>
                            <td className="py-3 px-6 text-center whitespace-nowrap">
                                {bill.transaction_type === 'charge' ? (
                                    <span className="text-red-500">↑ {bill.amount}</span>
                                ) : (
                                    <span className="text-green-500">↓ {bill.amount}</span>
                                )}
                            </td>
                            <td className="py-3 px-6 text-center whitespace-nowrap">{bill.transaction_time}</td>
                            <td className="py-3 px-6 text-center whitespace-nowrap">{bill.transaction_description}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="flex justify-between items-center mt-4">
                    <button onClick={() => setCurrentPage(prev => prev > 1 ? prev - 1 : prev)}
                            disabled={currentPage <= 1}
                            className={`px-4 py-2 text-white font-semibold bg-orange-500 hover:bg-orange-600 rounded ${currentPage <= 1 ? 'opacity-50 cursor-default' : ''}`}>
                        Prev
                    </button>
                    <span>Page: {currentPage}</span>
                    <div>
                        <label htmlFor="bills-per-page" className="mr-2">Bills per page:</label>
                        <select id="bills-per-page" value={billsPerPage} onChange={handleBillsPerPageChange}
                                className="border bg-white rounded px-3 py-2 outline-none">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                    <button onClick={() => setCurrentPage(prev => prev + 1)}
                            className="px-4 py-2 text-white font-semibold bg-orange-500 hover:bg-orange-600 rounded">
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BillsPage;
