import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSpring, animated } from 'react-spring';
import 'tailwindcss/tailwind.css';

const CookieConsentPage = ({ setIsModalOpen}) => {
    const [cookies, setCookie] = useCookies(['userAcceptsCookies']);

    const animation = useSpring({
        transform: 'translateY(0%)',
        from: { transform: 'translateY(100%)' },
    });

    const handleAccept = () => {
        setCookie('userAcceptsCookies', true, { path: '/' });
        setIsModalOpen(false);
    };

    const handleReject = () => {
        setIsModalOpen(false);
    };

    return (
        <animated.div style={animation} className="fixed bottom-0 w-full p-4 bg-white bg-opacity-80 shadow-lg">
            <div className="container mx-auto text-center">
                <p className="mb-4 text-lg text-gray-700">We use cookies to enhance your experience. Do you accept our use of cookies?</p>
                <button onClick={handleAccept} className="px-4 py-2 mr-2 bg-blue-500 text-white rounded shadow-md hover:shadow-lg">Accept</button>
                <button onClick={handleReject} className="px-4 py-2 bg-red-500 text-white rounded shadow-md hover:shadow-lg">Reject</button>
            </div>
        </animated.div>
    );
};

export default CookieConsentPage;
