import React, { useEffect, useState } from 'react';
import {postFunc} from "../util/httpclient";

const EditUserRole = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10);

    const roleMapping = {
        '1': 'New Users',
        '2': 'Paid Users',
        '3': 'Internal Employees',
        '4': 'Admin',
    };

    const inverseRoleMapping = {
        'New Users': '1',
        'Paid Users': '2',
        'Internal Employees': '3',
        'Admin': '4',
    };

    useEffect(() => {
        postFunc('/user/lists', {'per_page': usersPerPage, 'page': currentPage}, (data) => {
            if (data.ret == 200) {
                setUsers(data.users);
            } else {
                alert(data.err);
            }
        }, (error) => {
            console.log(error);
            alert("get user lists failed")
        }, () => {
        }, true)
    }, [currentPage, usersPerPage])

    const handleUsersPerPageChange = (event) => {
        setUsersPerPage(event.target.value);
    }

    const handleUserRoleChange = (userId, event) => {
        const newRole = inverseRoleMapping[event.target.value];
        postFunc('/user/role/edit', {'user_id': userId, 'role': newRole}, (data) => {
            if (data.ret === 200) {
                // update role in local state as well
                setUsers(users.map(user => user.user_id === userId ? {...user, role: newRole} : user));
                alert("update user role success");
            } else {
                alert(data.err);
            }
        }, (error) => {
            console.log(error);
            alert("Role change failed");
        }, () => {
        }, true)
    }

    return (
        <div className="flex items-center justify-center h-screen bg-orange-50">
            <div className="w-full md:w-2/3 lg:w-1/2 xl:w-1/3">
                <table className="table-auto w-full">
                    <thead>
                    <tr className="bg-orange-200 text-gray-600 uppercase text-sm leading-normal">
                        <th className="py-3 px-6 text-left">User ID</th>
                        <th className="py-3 px-6 text-left">Username</th>
                        <th className="py-3 px-6 text-center">From</th>
                        <th className="py-3 px-6 text-center">Role</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user, index) => (
                        <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-orange-50 hover:bg-orange-100 transition ease-in-out duration-150"}>
                            <td className="py-3 px-6 text-left whitespace-nowrap">{user.user_id}</td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">{user.username}</td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">{user.authprovider}</td>
                            <td className="py-3 px-6 text-center whitespace-nowrap">
                                <select value={roleMapping[user.role]}
                                        onChange={(event) => handleUserRoleChange(user.user_id, event)}
                                        className="border bg-white rounded px-3 py-2 outline-none">
                                    {Object.values(roleMapping).map(role => (
                                        <option key={role} value={role}>{role}</option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="flex justify-between items-center mt-6">
                    <button onClick={() => setCurrentPage(prev => prev > 1 ? prev - 1 : prev)}
                            disabled={currentPage <= 1}
                            className={`px-4 py-2 text-white font-semibold bg-orange-400 hover:bg-orange-500 rounded transition ease-in-out duration-150 ${currentPage <= 1 ? 'opacity-50 cursor-default' : ''}`}>
                        Prev
                    </button>
                    <span className="text-lg">Page: {currentPage}</span>
                    <div>
                        <label htmlFor="users-per-page" className="mr-2 text-lg">Users per page:</label>
                        <select id="users-per-page" value={usersPerPage} onChange={handleUsersPerPageChange}
                                className="border bg-white rounded px-3 py-2 outline-none text-lg">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                    <button onClick={() => setCurrentPage(prev => prev + 1)}
                            className="px-4 py-2 text-white font-semibold bg-orange-400 hover:bg-orange-500 rounded transition ease-in-out duration-150">
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EditUserRole;
