import React, {useEffect, useState} from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {use} from "marked";
import {getFunc, postFunc} from "../util/httpclient";

const RechargePage = () => {
    const [amount, setAmount] = useState('5');
    const [customAmount, setCustomAmount] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('paypal');
    const [clientId, setClientId] = useState("");
    const [isClientIdLoaded, setIsClientIdLoaded] = useState(false);
    const [rechargeMessage, setRechargeMessage] = useState('');



    useEffect(() => {
        getFunc("/bills/paypal", (data) => {
            if (data.ret != 200) {
                alert(`get paypal client id failed, ${data.err}`);
            } else {
                setClientId(data.client_id);
                setIsClientIdLoaded(true);
            }
        }, (error) => {
            console.log(error);
            alert("get paypal client id failed")
        }, () => {
        }, true);
    }, []);


    const handleAmountChange = (newAmount) => {
        setAmount(newAmount);
        if (newAmount !== 'custom') {
            setCustomAmount('');
        }
    };

    const handleCustomAmountChange = (event) => {
        setCustomAmount(event.target.value);
    };

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handleRechargeClick = () => {
        const rechargeAmount = amount === 'custom' ? customAmount : amount;
        // Insert recharge logic here.
        alert(`Recharge ${rechargeAmount} with ${paymentMethod}`);
    };

    const createOrder = (data, actions) => {
        let rechargeAmount = amount === 'custom' ? customAmount : amount;
        setAmount(currentAmount => {
            rechargeAmount = currentAmount === 'custom' ? customAmount : currentAmount;
            return currentAmount;
        });
        setCustomAmount(currentCustomAmount => {
            if (amount === 'custom') {
                rechargeAmount = currentCustomAmount;
            }
            return currentCustomAmount;
        });

        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: rechargeAmount,
                    currency_code: 'GBP'
                }
            }]
        });
    }

    const handlePayoutSuccess = (data, actions) => {
        return actions.order.capture().then(function(details) {
            // Show a success message to your buyer

            const msg = 'Transaction completed by ' + details.payer.name.given_name

            // Now call the backend to update the user's balance
            let rechargeAmount = amount === 'custom' ? customAmount : amount;

            setAmount(currentAmount => {
                rechargeAmount = currentAmount === 'custom' ? customAmount : currentAmount;
                return currentAmount;
            });

            setCustomAmount(currentCustomAmount => {
                if (amount === 'custom') {
                    rechargeAmount = currentCustomAmount;
                }
                return currentCustomAmount;
            });

            postFunc('/bills/paypal/recharge', { amount: rechargeAmount, msg}, (data) => {
                if (data.ret === 200) {
                    setRechargeMessage(`Your balance has been successfully recharged with £${rechargeAmount}!`);
                } else {
                    alert('Failed to recharge your balance: ' + data.err);
                }
            }, (error) => {
                console.log(error);
                alert('Failed to recharge your balance. Please try again later.');
            }, () => {}, true);
        });
    };


    const handlePayoutFailure = (err) => {
        console.error(err);
        alert('There was an error processing your payment. Please try again.');
    };

    if (!isClientIdLoaded) {
        return null;  // or a loading spinner
    }

    return (
        <PayPalScriptProvider options={{ "client-id": clientId, currency: "GBP"}}>
            <div className="flex items-center justify-center h-screen bg-orange-50">
                <div className="w-full max-w-md">
                    <form className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
                        {rechargeMessage && (
                            <div className="mb-4 text-center text-green-500">
                                {rechargeMessage}
                            </div>
                        )}

                            <div className="mb-4">
                                <label className="block mb-2 text-sm font-bold text-gray-700">
                                    Amount
                                </label>
                                <div className="grid grid-cols-2 gap-4">
                                    {['5', '10', '50', 'custom'].map((value) => (
                                        <div key={value}>
                                            <label className="flex items-center cursor-pointer">
                                                <input
                                                    type="radio"
                                                    className="form-radio h-5 w-5 text-orange-600"
                                                    name="amount"
                                                    value={value}
                                                    checked={amount === value}
                                                    onChange={() => handleAmountChange(value)}
                                                />
                                                <span className="ml-2 text-gray-700">{value === 'custom' ? 'Custom' : `£${value}`}</span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                {amount === 'custom' && (
                                    <input
                                        className="w-full px-3 py-2 mt-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        type="number"
                                        placeholder="Enter custom amount"
                                        value={customAmount}
                                        onChange={handleCustomAmountChange}
                                    />
                                )}
                            </div>
                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="payment-method">
                                    Payment Method
                                </label>
                                <select
                                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    id="payment-method"
                                    value={paymentMethod}
                                    onChange={handlePaymentMethodChange}
                                >
                                    <option value="paypal">PayPal</option>
                                </select>
                            </div>
                        <div className="flex items-center justify-between">
                            {paymentMethod === 'paypal' ?
                                <PayPalButtons createOrder={createOrder} onApprove={handlePayoutSuccess} onError={handlePayoutFailure} />
                                :
                                <button
                                    className="w-full px-4 py-2 font-bold text-white bg-orange-500 rounded-full hover:bg-orange-700 focus:outline-none focus:shadow-outline"
                                    type="button"
                                    onClick={handleRechargeClick}
                                >
                                    Recharge
                                </button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </PayPalScriptProvider>
    );
};

export default RechargePage;
