import React, {useState, useEffect} from 'react';
import {postFunc, getFunc} from "../util/httpclient";
import {Link, useNavigate} from 'react-router-dom';
import {RiDeleteBin6Line} from 'react-icons/ri';

export const NewProjectPage = () => {
    const [projectName, setProjectName] = useState('');
    const [notifyEmail, setNotifyEmail] = useState('');
    const navigate = useNavigate();

    const handleProjectNameChange = (event) => {
        setProjectName(event.target.value);
    };

    const handleNotifyEmailChange = (event) => {
        setNotifyEmail(event.target.value);
    };

    const toggleList = () => {
        const dropdown = document.getElementById('dropdown-example');

        if (!dropdown.classList.contains('hidden')) {
            dropdown.classList.toggle('hidden')
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const user_id = localStorage.getItem("user_id");
        const parmas = {
            "user_id": user_id,
            "project_name": projectName,
            "notify_email": notifyEmail
        }

        postFunc("/project/add", parmas, (data) => {
            if (data.ret == 200) {
                alert("Add new project success");
                toggleList();
                navigate("/");
            } else {
                alert(data.err);
            }
        }, (error) => {
            console.log(error);
            alert("Add project failed")
        }, () => {
            setProjectName('');
            setNotifyEmail('');
        }, true)
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-orange-50">
            <h1 className="text-3xl text-orange-500"> New Project </h1>
            <form onSubmit={handleSubmit} className="w-full max-w-md mt-6">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="projectName">
                        Project Name
                    </label>
                    <input
                        type="text"
                        id="projectName"
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder=""
                        value={projectName}
                        onChange={handleProjectNameChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="notifyEmail">
                        Notify Email
                    </label>
                    <input
                        type="email"
                        id="notifyEmail"
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder=""
                        value={notifyEmail}
                        onChange={handleNotifyEmailChange}
                    />
                </div>
                <button
                    type="submit"
                    className="w-full px-4 py-2 font-bold text-white bg-orange-500 rounded-full hover:bg-orange-700 focus:outline-none focus:shadow-outline"
                >
                    Create Project
                </button>
            </form>
        </div>
    );
};

export const ListProjectPage = () => {
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();

    const toggleProjectsDropdown = () => {
        const user_id = localStorage.getItem("user_id");
        const api = `/project/list?user_id=${user_id}`

        getFunc(api, (data) => {
            if (data.ret == 200) {
                setProjects(data.projects)
            } else {
                alert(data.err);
            }
        }, (error) => {
            console.log(error);
            alert("list projects failed")
        }, () => {
        }, true);

        const dropdown = document.getElementById('dropdown-example');
        dropdown.classList.toggle('hidden');
    };


    const handleDeleteProject = (projectId) => {
        postFunc("/project/delete", {"project_id": projectId}, (data) => {
            if (data.ret == 200) {
                alert("delete success");
                toggleProjectsDropdown();
                navigate("/");
            } else {
                alert(data.err);
            }
        }, (error) => {
            console.log(error);
            alert("delete error")
        }, () => {
        }, true)
    }

    return (
        <li>
            <button
                type="button"
                className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                onClick={toggleProjectsDropdown}
                aria-controls="dropdown-example"
            >
                <svg
                    aria-hidden="true"
                    className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                    ></path>
                </svg>
                <span className="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item={true.toString()}>
          Projects
        </span>
                <svg
                    sidebar-toggle-item={true.toString()}
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    ></path>
                </svg>
            </button>
            <ul id="dropdown-example" className="hidden py-2 space-y-2">
                {projects !== undefined && projects
                    .sort((a, b) => a.project_id - b.project_id) // 按照 project_id 排序
                    .map(project => (
                        <li key={project.project_id}>
                            <div
                                className="flex items-center justify-between w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                                <Link to={`/projects/${project.project_id}`}>
                                    {project.project}
                                </Link>

                                <span onClick={() => handleDeleteProject(project.project_id)}>
                                    <RiDeleteBin6Line className="text-red-500 cursor-pointer"/>
                                </span>
                            </div>
                        </li>
                    ))}
            </ul>
        </li>
    );
}
