import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Link, Navigate, Route, Routes} from 'react-router-dom';
import AuthenticationPage, {ForgotPasswordPage, ResetPasswordPage, SignUpPage} from './component/auth';

import {WelcomePage} from './component/welcome';
import {ListProjectPage, NewProjectPage} from './component/project';
import ConfigPage from './component/config';

import dashboardImage from './images/dashboard.png';
import {HelpPage} from "./component/help";
import {useCookies} from 'react-cookie';
import EditTokenPrice from "./component/editToken";
import EditUserRole from "./component/editUserRole";
import BillsPage from "./component/bills";
import RechargePage from "./component/recharge";
import CookieConsentPage from "./component/cookieConsentPage";


const App = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [cookies, setCookie] = useCookies(['isLoggedIn']);
    const [userId, setUserId] = useState(localStorage.getItem('username') || '');
    const [role, setRole] = useState(localStorage.getItem("role"));

    const [isAdminOpen, setIsAdminOpen] = useState(false);


    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setUserId(localStorage.getItem('username'));
        setRole(localStorage.getItem("role"));
    }, [cookies.isLoggedIn]);

    return (<div className="App">
        <Router>
            <div>
                {cookies.isLoggedIn === 'true' ? (<div>
                    <button
                        onClick={toggleSidebar}
                        className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    >
                        <span className="sr-only">Open sidebar</span>
                        <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                clipRule="evenodd"
                                fillRule="evenodd"
                                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                            ></path>
                        </svg>
                        <span className="ml-2">Open Sidebar</span>
                    </button>

                    <aside
                        id="sidebar-multi-level-sidebar"
                        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${isOpen ? 'translate-x-0' : '-translate-x-full sm:-translate-x-0'}`}
                        aria-label="Sidebar"
                    >
                        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                            <ul className="space-y-2 font-medium">
                                <li>
                                    <Link
                                        to="/"
                                        className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                    >
                                        <img src={dashboardImage} alt="Synfakeit Logo" className=""/>
                                    </Link>
                                </li>

                                {role === '4' && (
                                    <li>
                                        <div className="space-y-2">
                                        <span
                                            className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        >
                        <svg id="adminIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="w-6 h-6">
                            <path
                                d="M22 12.081V10c0-5.523-4.477-10-10-10S2 4.477 2 10v2.081C2 13.721 5.582 16.711 12 21.317c6.418-4.606 10-7.596 10-9.236z"></path>
                        </svg>


                        <span className="flex-1 ml-3 text-left" onClick={() => setIsAdminOpen(!isAdminOpen)}>Admin</span>
                            </span>
                                            {isAdminOpen && (
                                                <ul className="ml-6">
                                                <li>
                                                    <Link
                                                        className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                                        to="/admin/edit-token-price"
                                                    >
                                                        <span
                                                            className="flex-1 ml-3 text-left">Edit Api Token Price</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text:white dark:hover:bg-gray-700"
                                                        to="/admin/edit-user-role"
                                                    >
                                                                <span
                                                                    className="flex-1 ml-3 text-left">Edit User Role</span>
                                                    </Link>
                                                </li>
                                            </ul>)}
                                        </div>
                                    </li>)
                                }


                                <li>
                                    <Link
                                        className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        to="/bills"
                                    >
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M3 3h18v18H3V3zm3 3h12v12H6V6zm2 2v8h8V8H8z"
                                            ></path>
                                        </svg>
                                        <span className="flex-1 ml-3 text-left">Bills</span>
                                    </Link>
                                </li>


                                <li>
                                    <Link
                                        className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        to="/recharge"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9a2 2 0 012-2h16a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                        <span className="flex-1 ml-3 text-left">Recharge</span>
                                    </Link>
                                </li>


                                <li>
                                    <Link
                                        className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        to="/project/add"
                                    >
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                            ></path>
                                        </svg>
                                        <span className="flex-1 ml-3 text-left">New Project</span>
                                    </Link>
                                </li>

                                <ListProjectPage/>

                                <li>
                                    <Link
                                        className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        to="/help"
                                    >
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 15v-2m0 0V9m0 6h.01M12 9h.01m0 0v2m0-2h.01M20 4a8 8 0 11-16 0 8 8 0 0116 0z"
                                            ></path>
                                        </svg>
                                        <span className="flex-1 ml-3 text-left">Help</span>
                                    </Link>
                                </li>


                                <li>
                                    <Link
                                        className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        to="/"
                                        onClick={() => {
                                            setCookie('isLoggedIn', false, {path: '/'});
                                            localStorage.clear();
                                        }}
                                    >
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M4 3v18h16V3H4zm4 11h8m-4-8v12"
                                            ></path>
                                        </svg>
                                        <span className="flex-1 ml-3 text-left">Logout</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </aside>

                    <Routes>
                        <Route path="/" element={<WelcomePage/>}/>
                        <Route path="/project/add" element={<NewProjectPage/>}/>
                        <Route path="/projects/:project_id" element={<ConfigPage/>}/>
                        <Route path="/help" element={<HelpPage/>}/>
                        <Route path="/bills" element={<BillsPage />} />
                        <Route path="/admin/edit-token-price" element={<EditTokenPrice />} />
                        <Route path="/admin/edit-user-role" element={<EditUserRole />} />
                        <Route path="/recharge" element={ <RechargePage />} />
                    </Routes>
                </div>) : (<Routes>
                    <Route path="/login" element={<AuthenticationPage/>}/>
                    <Route path="/signup" element={<SignUpPage/>}/>
                    <Route path="/forget" element={<ForgotPasswordPage/>}/>
                    <Route path="/passwordreset" element={<ResetPasswordPage/>}/>
                    <Route path="*" element={<Navigate to="/login" replace/>}/>
                </Routes>)}
            </div>
        </Router>
    </div>);
};

export default App;
