



export const HelpPage = () => {

    return (
        <div className="relative bg-opacity-50 pl-64">
            <iframe className="w-full h-screen" frameborder="0" allowFullScreen src="https://dubble.so/guides/workflow-with-mgggcloud-and-wwwideamotiveco-fq3ohw11sxj0ei8b0ibw?embed=1">
            </iframe>
        </div>
    )

}