import React, {useEffect, useState} from 'react';
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import {getFunc, postFunc} from "../util/httpclient";
import {useNavigate} from 'react-router-dom';
import {useCookies} from "react-cookie";

const GoogleLoginButton = () => {
    const [clientId, setClientId] = useState('');
    const [cookies, setCookie] = useCookies(['isLoggedIn']);
    const navigate = useNavigate()

    useEffect(() => {
        getFunc("/auth/google", (data) => {
            if (data.ret != 200) {
                alert(`get google client id failed, ${data.err}`);
            } else {
                setClientId(data.url);
            }

        }, (error) => {
            console.log(error);
            alert("get google client id failed")
        }, () => {
        }, false);
    }, []);

    const handleGoogleLoginSuccess = (response) => {
        postFunc('/auth/google/callback', {credential: response.credential}, (data) => {
            if (data.ret == 200) {
                localStorage.setItem("token", data.token);
                localStorage.setItem("user_id", data.user_id);
                localStorage.setItem("role", data.role);
                localStorage.setItem("username", data.username);
                setCookie('isLoggedIn', true, {path: '/'});
                navigate("/");
            } else {
                alert(data.err)
            }
        }, (error) => {
            console.log(error);
            alert("google auth failed")
        }, () => {
        }, false);
    };

    const handleGoogleLoginFailure = (response) => {
        console.log('Google Login Failed:', response.error);
        alert("Google Login Failed");
    };

    if (!clientId) {
        return null;
    }


    return (
        <GoogleOAuthProvider clientId={clientId}>
            <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onFailure={handleGoogleLoginFailure}
                buttonText="Login"
                className="bg-orange-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded mr-2 flex items-center"
            />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButton;

