import React, { useState } from 'react';
import {postFunc} from "../util/httpclient";

const EditTokenPrice = () => {
    const [price, setPrice] = useState(0.02); // assuming initial price is 0.02$

    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const username = localStorage.getItem("username");

        postFunc("/edit/price", {username, price}, (data) => {
                if (data.ret == 200) {
                    alert("update price success");
                } else {
                    alert(data.err);
                }
            }, (error) => {
                console.log(error);
                alert("update price failed")
            }, () => {},
            true)
    }

    return (
        <div className="flex items-center justify-center h-screen bg-orange-50">
            <form onSubmit={handleFormSubmit} className="bg-white shadow-lg rounded-lg px-12 py-8 mb-4">
                <div className="mb-8">
                    <label className="block text-gray-700 text-lg font-semibold mb-4" htmlFor="price">
                        Price per 1K tokens
                    </label>
                    <div className="relative">
                        <span className="absolute right-0 pr-3 self-center text-gray-400 py-2">£</span>
                        <input
                            id="price"
                            type="number"
                            step="0.01"
                            value={price}
                            onChange={handlePriceChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-right mr-4 transition ease-in-out duration-150"
                            required
                        />
                    </div>
                </div>
                <div className="flex items-center justify-end">
                    <button
                        type="submit"
                        className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                    >
                        Update Price
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EditTokenPrice;
