import React, {useState, useEffect} from 'react';
import {getFunc, postFunc} from "../util/httpclient";
import {useNavigate, Link} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {FaEye, FaEyeSlash, FaGoogle, FaMicrosoft} from 'react-icons/fa';
import dashboardImage from '../images/dashboard.png';
import {useCookies} from 'react-cookie';
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import GoogleLoginButton from "./googleLogin";
import MicrosoftLogin from "./msLogin";
import CookieConsentPage from "./cookieConsentPage";


const AuthenticationPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['isLoggedIn', 'userAcceptsCookies']);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(true);


    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSignUp = (e) => {
        navigate("/signup")
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = () => {
        postFunc("/auth", {username, password}, (data) => {
            if (data.ret == 200) {
                localStorage.setItem("token", data.token);
                localStorage.setItem("user_id", data.user_id);
                localStorage.setItem("role", data.role);
                localStorage.setItem("username", data.username);
                setCookie('isLoggedIn', true, {path: '/'});
                navigate("/");
            } else {
                alert(data.err)
            }
        }, (error) => {
            console.log(error);
            alert("auth failed")
        }, () => {
        }, false);
    };

    const handleForgotPassword = () => {
        navigate('/forget');
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="p-8 max-w-lg w-full bg-white rounded-xl shadow-md space-y-10">
                <div className="flex justify-center">
                    <img
                        src={dashboardImage}
                        alt="Synfakeit Logo"
                        className="w-full"
                    />
                </div>
                <div className="space-y-6">
                    <div className="space-y-1">
                        <label className="block text-gray-700 font-semibold">Username:</label>
                        <input
                            type="text"
                            value={username}
                            onChange={handleUsernameChange}
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
                        />
                    </div>
                    <div className="relative">
                        <label className="block text-gray-700 font-semibold">Password:</label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={handlePasswordChange}
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="text-gray-500 hover:text-orange-500"
                            >
                                {showPassword ? <FaEyeSlash/> : <FaEye/>} {/* 使用 Font Awesome 图标 */}
                            </button>
                        </div>
                    </div>
                    <div className="flex space-x-4">
                        <button
                            onClick={handleLogin}
                            className="w-full bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Sign In
                        </button>
                        <button
                            onClick={handleSignUp}
                            className="w-full bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Sign Up
                        </button>
                    </div>

                    <div className="text-center">
                        <p
                            className="text-orange-500 hover:text-orange-700 cursor-pointer"
                            onClick={handleForgotPassword}
                        >
                            Forgot Password?
                        </p>
                    </div>

                    <hr className="my-4"/>

                    <div className="flex justify-center space-x-2">
                        <div className="flex-grow">
                            <GoogleLoginButton/>
                        </div>
                        <div className="flex-grow">
                            <MicrosoftLogin/>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <CookieConsentPage setIsModalOpen={setIsModalOpen} />

                </div>
            )}
        </div>
    );
};


export const SignUpPage = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();


    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSignUp = () => {
        postFunc(
            "/user/add", {
                "username": username,
                "password": password,
                "email": email
            }, (data) => {
                if (data.ret == 200) {
                    alert("Register success");
                    navigate("/")
                } else {
                    alert(data.err);
                }
            }, (error) => {
                console.log(error);
                alert("Register failed")
            }, () => {
            }, false
        )
    };


    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="p-8 max-w-md w-full bg-white rounded-xl shadow-md space-y-10">
                <h1 className="text-3xl text-center font-bold text-orange-600">Sign Up</h1>

                <div className="space-y-6">
                    <div className="space-y-1">
                        <label className="block text-gray-700 font-semibold">Username:</label>
                        <input
                            type="text"
                            value={username}
                            onChange={handleUsernameChange}
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:border-orange-400 focus:ring-1 focus:ring-orange-400"
                        />
                    </div>
                    <div className="space-y-1">
                        <label className="block text-gray-700 font-semibold">Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:border-orange-400 focus:ring-1 focus:ring-orange-400"
                        />
                    </div>
                    <div className="space-y-1">
                        <label className="block text-gray-700 font-semibold">Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:border-orange-400 focus:ring-1 focus:ring-orange-400"
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            onClick={handleSignUp}
                            className="w-full bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Sign Up
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

};


export const ForgotPasswordPage = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postFunc(
            "/user/forget",
            {"username": username, "email": email},
            (data) => {
                if (data.ret == 200) {
                    setMessage(`A password reset email has been sent to ${email}`);
                } else {
                    setMessage("Wrong information");
                }
            }, (error) => {
                alert(error);
            }, () => {
            }, false
        )
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="p-8 max-w-md w-full bg-white rounded-xl shadow-md space-y-10">
                <h1 className="text-3xl text-center font-bold text-orange-600">Enter the username and email to reset
                    password</h1>

                {message ? (
                    <p className="text-green-500 text-center">{message}</p>
                ) : (
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="space-y-1">
                            <label className="block text-gray-700 font-semibold">Username:</label>
                            <input
                                type="text"
                                value={username}
                                onChange={handleUsernameChange}
                                className="w-full border border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:border-orange-400 focus:ring-1 focus:ring-orange-400"
                            />
                        </div>
                        <div className="space-y-1">
                            <label className="block text-gray-700 font-semibold">Email:</label>
                            <input
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                className="w-full border border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:border-orange-400 focus:ring-1 focus:ring-orange-400"
                            />
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className="w-full bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                )}

                {/* 添加返回登录页面的链接 */}
                <div className="text-center">
                    <Link to="/login" className="text-blue-500 hover:text-blue-700">
                        Return to Login Page
                    </Link>
                </div>
            </div>
        </div>
    );

};


export const ResetPasswordPage = () => {
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const location = useLocation();

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // 在这里处理提交逻辑，比如发送修改密码的请求

        const searchParams = new URLSearchParams(location.search);
        const data = searchParams.get('data');

        console.log(data);

        postFunc(
            "/user/edit", {
                data,
                "password": newPassword
            }, (data) => {
                if (data.ret == 200) {
                    setMessage('Password has been successfully updated');
                } else {
                    setMessage(`invalid request, ${data.err}`);
                }
            }, (error) => {
                console.log(error);
                setMessage('invalid request');
            }, () => {

            }, false
        )
    };

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center">
                <h1 className="text-4xl text-center mb-4">Enter a new password</h1>

                {message ? (
                    <p className="text-green-500 text-center">{message}</p>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="text-left my-4">
                            <div className="mb-4">
                                <label className="block mb-2">New Password:</label>
                                <input
                                    type="password"
                                    value={newPassword}
                                    onChange={handleNewPasswordChange}
                                    className="border border-gray-300 rounded px-4 py-2 w-64"
                                />
                            </div>
                            <div className="flex">
                                <button
                                    type="submit"
                                    className="bg-orange-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded mr-2"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                )}

                {/* 添加返回登录页面的链接 */}
                <Link to="/login" className="block text-left mt-4 text-blue-800 py-12">
                    Return to Login Page
                </Link>
            </div>
        </div>
    );
};


export default AuthenticationPage;
