import React, {useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';
import {getFunc, postFunc} from "../util/httpclient";

const ConfigPage = () => {

    const { project_id } = useParams();

    const [selectedFormats, setSelectedFormats] = useState([]);
    const [configurations, setConfigurations] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [isDownload, setIsDownload] = useState(false);

    const [email, setEmail] = useState("");

    useEffect(() => {
        const api = `/projects/${project_id}`
        getFunc(api, (data) => {
            if (data.ret == 200) {
                if (data.project.config !== undefined && data.project.config !== null) {
                    const updatedConfigurations = JSON.parse(data.project.config)
                    setConfigurations(updatedConfigurations);
                    setSelectedFormats(Object.keys(updatedConfigurations));
                }
                setEmail(data.project.email);
            } else {
                alert(`load configuration failed, err: ${data.err}`)
            }
        }, (error) => {
            console.log(error);
            alert("load configuration failed")
        }, () => {}, true)
    }, [project_id]);


    const handleFormatChange = (format) => {
        const isFormatSelected = selectedFormats.includes(format);
        let updatedFormats;
        let updatedConfigurations = { ...configurations };

        if (isFormatSelected) {
            updatedFormats = selectedFormats.filter((selectedFormat) => selectedFormat !== format);
            delete updatedConfigurations[format];
        } else {
            updatedFormats = [...selectedFormats, format];
            updatedConfigurations[format] = {};
        }

        setSelectedFormats(updatedFormats);
        setConfigurations(updatedConfigurations);
    };



    const handleSave = () => {
        const url = "/project/edit"
        const parmas = {
            "project_id": project_id,
            "config": configurations
        }

        postFunc(url, parmas, (data) => {
            if (data.ret == 200) {
                console.log("Save success");
            } else {
                alert(`Save failed, err: ${data.err}`);
            }
        }, (error) => {
            console.log(error);
            alert('Save failed');
        }, () => {}, true);
    }

    const handleConfigChange = (event, format) => {
        const {name, value} = event.target;
        const updatedConfigurations = {
            ...configurations,
            [format]: {
                ...configurations[format],
                [name]: value,
            },
        };

        setConfigurations(updatedConfigurations);
    };

    const handleSubConfigChange = (event, format, subFormat) => {
        const {name, value} = event.target;
        let updatedConfigurations;

        if (subFormat) {
            updatedConfigurations = {
                ...configurations,
                [format]: {
                    ...configurations[format],
                    [subFormat]: {
                        ...configurations[format]?.[subFormat],
                        [name]: value,
                    },
                },
            };
        } else {
            updatedConfigurations = {
                ...configurations,
                [format]: {
                    ...configurations[format],
                    [name]: value,
                },
            };
        }

        setConfigurations(updatedConfigurations);
    };

    const renderFormatOptions = () => {
        const formatOptions = [
            {value: 'docx', label: 'Docx'},
            {value: 'pdf', label: 'PDF'},
            {value: 'excel', label: 'Excel'},
            {value: 'mbox', label: 'Mbox'},
            {value: 'pst', label: 'PST'},
        ];

        return (
            <div className="flex flex-col space-y-4">
                {formatOptions.map((option) => (
                    <label key={option.value} className="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            checked={selectedFormats.includes(option.value)}
                            onChange={() => handleFormatChange(option.value)}
                            className="form-checkbox h-4 w-4 text-orange-500"
                        />
                        <span className="text-gray-900 font-semibold">{option.label}</span>
                    </label>
                ))}
            </div>
        );
    };

    const renderDocxConfigurations = () => {
        return (
            <div className="flex flex-col">
                <div className="flex items-center space-x-12 mb-4">
                    <label className="text-gray-700 w-22">Words:</label>
                    <input
                        type="number"
                        name="words"
                        value={configurations.docx?.words || '200'}
                        onChange={(event) => handleConfigChange(event, 'docx')}
                        className="form-input w-24 border border-gray-300 focus:border-blue-500"
                    />
                </div>

                <div className="flex items-center space-x-4 mb-4">
                    <label className="text-gray-700 w-22">Paragraphs:</label>
                    <input
                        type="number"
                        name="paragraphs"
                        value={configurations.docx?.paragraphs || '1'}
                        onChange={(event) => handleConfigChange(event, 'docx')}
                        className="form-input w-24 border border-gray-300 focus:border-blue-500"
                    />
                </div>

                <div className="flex items-center space-x-4 mb-4">
                    <label className="text-gray-700 w-22">Description:</label>
                    <input
                        name="description"
                        value={configurations.docx?.description || ''}
                        onChange={(event) => handleConfigChange(event, 'docx')}
                        className="form-input w-96 border border-gray-300 focus:border-blue-500"
                        required
                    />
                </div>

                <div className="flex items-center space-x-7 mb-4">
                    <label className="text-gray-700 w-22">Files num:</label>
                    <input
                        type="number"
                        name="file_nums"
                        value={configurations.docx?.file_nums || '1'}
                        onChange={(event) => handleConfigChange(event, 'docx')}
                        className="form-input w-24 border border-gray-300 focus:border-blue-500"
                    />
                </div>

            </div>
        );
    };

    const renderPdfConfigurations = () => {
        return (
            <div className="flex flex-col">
                <div className="flex items-center space-x-12 mb-4">
                    <label className="text-gray-700 w-22">Words:</label>
                    <input
                        type="number"
                        name="words"
                        value={configurations.pdf?.words || '200'}
                        onChange={(event) => handleConfigChange(event, 'pdf')}
                        className="form-input w-24 border border-gray-300 focus:border-blue-500"
                    />
                </div>

                <div className="flex items-center space-x-4 mb-4">
                    <label className="text-gray-700 w-22">Paragraphs:</label>
                    <input
                        type="number"
                        name="paragraphs"
                        value={configurations.pdf?.paragraphs || '1'}
                        onChange={(event) => handleConfigChange(event, 'pdf')}
                        className="form-input w-24 border border-gray-300 focus:border-blue-500"
                    />
                </div>

                <div className="flex items-center space-x-4 mb-4">
                    <label className="text-gray-700 w-22">Description:</label>
                    <input
                        name="description"
                        value={configurations.pdf?.description || ''}
                        onChange={(event) => handleConfigChange(event, 'pdf')}
                        className="form-input w-96 border border-gray-300 focus:border-blue-500"
                    />
                </div>

                <div className="flex items-center space-x-7 mb-4">
                    <label className="text-gray-700 w-22">Files num:</label>
                    <input
                        type="number"
                        name="file_nums"
                        value={configurations.pdf?.file_nums || '1'}
                        onChange={(event) => handleConfigChange(event, 'pdf')}
                        className="form-input w-24 border border-gray-300 focus:border-blue-500"
                    />
                </div>
            </div>
        );
    };

    const renderExcelConfigurations = () => {
        return (
            <div className="flex flex-col space-y-4">
                <div className="flex items-center space-x-14">
                    <label className="text-gray-700 w-22">Rows:</label>
                    <input
                        type="number"
                        name="rows"
                        value={configurations.excel?.rows || '20'}
                        onChange={(event) => handleConfigChange(event, 'excel')}
                        className="px-2 py-1 form-input w-24 border border-gray-300 focus:border-blue-500 focus:outline-none"
                    />
                </div>

                <div className="flex items-center space-x-6">
                    <label className="text-gray-700 w-20">Column headings:</label>
                    <input
                        type="text"
                        name="columns"
                        value={configurations.excel?.columns || ''}
                        onChange={(event) => handleConfigChange(event, 'excel')}
                        className="form-input w-96 h-8 border border-gray-300 focus:border-blue-500 focus:outline-none"
                    />
                </div>


                <div className="flex items-center space-x-4">
                    <label className="text-gray-700 w-22">Description:</label>
                    <input
                        name="description"
                        value={configurations.excel?.description || ''}
                        onChange={(event) => handleConfigChange(event, 'excel')}
                        className="px-2 py-1 form-input w-96 border border-gray-300 focus:border-blue-500 focus:outline-none"
                    />
                </div>

                <div className="flex items-center space-x-7 mb-4">
                    <label className="text-gray-700 w-22">Files num:</label>
                    <input
                        type="number"
                        name="file_nums"
                        value={configurations.excel?.file_nums || '1'}
                        onChange={(event) => handleConfigChange(event, 'excel')}
                        className="form-input w-24 border border-gray-300 focus:border-blue-500"
                    />
                </div>

            </div>
        );
    };

    const renderPstConfigurations = () => {
        const renderDocxAttachConfigurations = () => {
            return (
                <div className="flex flex-col">
                    <div className="flex items-center space-x-12 mb-4">
                        <label className="text-gray-700 w-22">Words:</label>
                        <input
                            type="number"
                            name="words"
                            value={configurations.pst?.docx?.words || 200}
                            onChange={(event) => handleSubConfigChange(event, 'pst', 'docx')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-4 mb-4">
                        <label className="text-gray-700 w-22">Paragraphs:</label>
                        <input
                            type="number"
                            name="paragraphs"
                            value={configurations.pst?.docx?.paragraphs || 1}
                            onChange={(event) => handleSubConfigChange(event, 'pst', 'docx')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-4 mb-4">
                        <label className="text-gray-700 w-22">Description:</label>
                        <input
                            name="description"
                            value={configurations.pst?.docx?.description}
                            onChange={(event) => handleSubConfigChange(event, 'pst', 'docx')}
                            className="form-input w-96 h-10 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-8 mb-4">
                        <label className="text-gray-700 w-22">Files num:</label>
                        <input
                            type="number"
                            name="file_nums"
                            value={configurations.pst?.docx?.file_nums || 0}
                            onChange={(event) => {
                                handleSubConfigChange(event, 'pst', 'docx')
                            }}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                            max="2"
                            min="0"
                        />
                    </div>
                </div>
            );
        };

        const renderPdfAttachConfigurations = () => {
            return (
                <div className="flex flex-col">
                    <div className="flex items-center space-x-12 mb-4">
                        <label className="text-gray-700 w-22">Words:</label>
                        <input
                            type="number"
                            name="words"
                            value={configurations.pst?.pdf?.words || 200}
                            onChange={(event) => handleSubConfigChange(event, 'pst', 'pdf')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-4 mb-4">
                        <label className="text-gray-700 w-22">Paragraphs:</label>
                        <input
                            type="number"
                            name="paragraphs"
                            value={configurations.pst?.pdf?.paragraphs || 1}
                            onChange={(event) => handleSubConfigChange(event, 'pst', 'pdf')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-4 mb-4">
                        <label className="text-gray-700 w-22">Description:</label>
                        <input
                            name="description"
                            value={configurations.pst?.pdf?.description}
                            onChange={(event) => handleSubConfigChange(event, 'pst', 'pdf')}
                            className="form-input w-96 h-10 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-8 mb-4">
                        <label className="text-gray-700 w-22">Files num:</label>
                        <input
                            type="number"
                            name="file_nums"
                            value={configurations.pst?.pdf?.file_nums || 0}
                            onChange={(event) => handleSubConfigChange(event, 'pst', 'pdf')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                            max="2"
                            min="0"
                        />
                    </div>
                </div>
            );
        };

        const renderExcelAttachConfigurations = () => {
            return (
                <div className="flex flex-col">
                    <div className="flex items-center space-x-14 mb-4">
                        <label className="text-gray-700 w-22">Rows:</label>
                        <input
                            type="number"
                            name="rows"
                            value={configurations.pst?.excel?.rows || 20}
                            onChange={(event) => handleSubConfigChange(event, 'pst', 'excel')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-2 mb-4">
                        <label className="text-gray-700 w-22">Column headings:</label>
                        <input
                            name="columns"
                            value={configurations.pst?.excel?.columns}
                            onChange={(event) => handleSubConfigChange(event, 'pst', 'excel')}
                            className="form-input w-96 h-10 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-4 mb-4">
                        <label className="text-gray-700 w-22">Description:</label>
                        <input
                            name="description"
                            value={configurations.pst?.excel?.description}
                            onChange={(event) => handleSubConfigChange(event, 'pst', 'excel')}
                            className="form-input w-96 h-10 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-8 mb-4">
                        <label className="text-gray-700 w-22">Files num:</label>
                        <input
                            type="number"
                            name="file_nums"
                            value={configurations.pst?.excel?.file_nums || 0}
                            onChange={(event) => handleSubConfigChange(event, 'pst', 'excel')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                            max="2"
                            min="0"
                        />
                    </div>
                </div>
            );
        };


        function getCurrentDate() {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }


        return (
            <div className="flex flex-col space-y-4">
                <div className="flex items-center space-x-4">
                    <label className="text-gray-700 w-22">n_messages_in_inbox:</label>
                    <input
                        type="number"
                        name="n_messages_in_inbox"
                        value={configurations.pst?.n_messages_in_inbox || '1'}
                        onChange={(event) => handleConfigChange(event, 'pst')}
                        className="px-2 py-1 form-input w-24 border border-gray-300 focus:border-blue-500 focus:outline-none"
                    />
                </div>

                <div className="flex items-center space-x-4">
                    <label className="text-gray-700 w-22">n_messages_in_sent:</label>
                    <input
                        type="number"
                        name="n_messages_in_sent"
                        value={configurations.pst?.n_messages_in_sent || '1'}
                        onChange={(event) => handleConfigChange(event, 'pst')}
                        className="px-2 py-1 form-input w-24 border border-gray-300 focus:border-blue-500 focus:outline-none"
                    />
                </div>

                <div className="flex items-center space-x-4">
                    <label className="text-gray-700 w-22">Description:</label>
                    <textarea
                        name="description"
                        value={configurations.pst?.description || ''}
                        onChange={(event) => handleConfigChange(event, 'pst')}
                        className="px-2 py-1 form-textarea w-96 h-20 border border-gray-300 focus:border-blue-500 focus:outline-none"
                    />
                </div>

                <div className="flex items-center space-x-4">
                    <label className="text-gray-700 w-22">Time Range:</label>
                    <div className="flex space-x-4">
                        <input
                            type="date"
                            name="start"
                            value={configurations.pst?.start || ''}
                            onChange={(event) => handleConfigChange(event, 'pst')}
                            className="px-2 py-1 form-input w-56 border border-gray-300 focus:border-blue-500 focus:outline-none"
                            max={getCurrentDate()}
                        />

                        <input
                            type="date"
                            name="end"
                            value={configurations.pst?.end || ''}
                            onChange={(event) => handleConfigChange(event, 'pst')}
                            className="px-2 py-1 form-input w-56 border border-gray-300 focus:border-blue-500 focus:outline-none"
                            max={getCurrentDate()}
                        />
                    </div>
                </div>

                <div className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        name="enable_cc"
                        checked={configurations.pst?.enable_cc || false}
                        onChange={(event) => {
                            const {checked} = event.target;
                            handleConfigChange({target: {name: 'enable_cc', value: checked}}, 'pst');
                        }}
                        className="form-checkbox h-4 w-4 text-indigo-600"
                    />
                    <label className="text-gray-700">Enable Multiple Emails in CC</label>
                </div>

                <div className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        name="enable_to"
                        checked={configurations.pst?.enable_to || false}
                        onChange={(event) => {
                            const {checked} = event.target;
                            handleConfigChange({target: {name: 'enable_to', value: checked}}, 'pst');
                        }}
                        className="form-checkbox h-4 w-4 text-indigo-600"
                    />
                    <label className="text-gray-700">Enable Multiple Emails in To</label>
                </div>

                <div className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        name="attachments"
                        checked={configurations.pst?.enable_attachments || false}
                        onChange={(event) => {
                            const {checked} = event.target;
                            handleConfigChange({target: {name: 'enable_attachments', value: checked}}, 'pst');
                        }}
                        className="form-checkbox h-4 w-4 text-indigo-600"
                    />
                    <label className="text-gray-700">Attachments</label>
                </div>

                {configurations.pst?.enable_attachments && (
                    <div className="flex flex-col space-y-4 mt-4">
                        <div className="border border-gray-300 rounded p-4">
                            <h3 className="text-lg font-semibold">Docx</h3>
                            {renderDocxAttachConfigurations()}
                        </div>

                        <div className="border border-gray-300 rounded p-4">
                            <h3 className="text-lg font-semibold">PDF</h3>
                            {renderPdfAttachConfigurations()}
                        </div>

                        <div className="border border-gray-300 rounded p-4">
                            <h3 className="text-lg font-semibold">Excel</h3>
                            {renderExcelAttachConfigurations()}
                        </div>
                    </div>
                )}

            </div>
        );
    };

    const renderMboxConfigurations = () => {
        const renderDocxAttachConfigurations = () => {
            return (
                <div className="flex flex-col">
                    <div className="flex items-center space-x-12 mb-4">
                        <label className="text-gray-700 w-22">Words:</label>
                        <input
                            type="number"
                            name="words"
                            value={configurations.mbox?.docx?.words || 200}
                            onChange={(event) => handleSubConfigChange(event, 'mbox', 'docx')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-4 mb-4">
                        <label className="text-gray-700 w-22">Paragraphs:</label>
                        <input
                            type="number"
                            name="paragraphs"
                            value={configurations.mbox?.docx?.paragraphs || 1}
                            onChange={(event) => handleSubConfigChange(event, 'mbox', 'docx')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-4 mb-4">
                        <label className="text-gray-700 w-22">Description:</label>
                        <input
                            name="description"
                            value={configurations.mbox?.docx?.description}
                            onChange={(event) => handleSubConfigChange(event, 'mbox', 'docx')}
                            className="form-input w-96 h-10 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-8 mb-4">
                        <label className="text-gray-700 w-22">Files num:</label>
                        <input
                            type="number"
                            name="file_nums"
                            value={configurations.mbox?.docx?.file_nums || 0}
                            onChange={(event) => handleSubConfigChange(event, 'mbox', 'docx')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                            max="2"
                            min="0"
                        />
                    </div>
                </div>
            );
        };

        const renderPdfAttachConfigurations = () => {
            return (
                <div className="flex flex-col">
                    <div className="flex items-center space-x-12 mb-4">
                        <label className="text-gray-700 w-22">Words:</label>
                        <input
                            type="number"
                            name="words"
                            value={configurations.mbox?.pdf?.words || 200}
                            onChange={(event) => handleSubConfigChange(event, 'mbox', 'pdf')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-4 mb-4">
                        <label className="text-gray-700 w-22">Paragraphs:</label>
                        <input
                            type="number"
                            name="paragraphs"
                            value={configurations.mbox?.pdf?.paragraphs || 1}
                            onChange={(event) => handleSubConfigChange(event, 'mbox', 'pdf')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-4 mb-4">
                        <label className="text-gray-700 w-22">Description:</label>
                        <input
                            name="description"
                            value={configurations.mbox?.pdf?.description}
                            onChange={(event) => handleSubConfigChange(event, 'mbox', 'pdf')}
                            className="form-input w-96 h-10 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-8 mb-4">
                        <label className="text-gray-700 w-22">Files num:</label>
                        <input
                            type="number"
                            name="file_nums"
                            value={configurations.mbox?.pdf?.file_nums || 0}
                            onChange={(event) => handleSubConfigChange(event, 'mbox', 'pdf')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                            max="2"
                            min="0"
                        />
                    </div>
                </div>
            );
        };

        const renderExcelAttachConfigurations = () => {
            return (
                <div className="flex flex-col">
                    <div className="flex items-center space-x-14 mb-4">
                        <label className="text-gray-700 w-22">Rows:</label>
                        <input
                            type="number"
                            name="rows"
                            value={configurations.mbox?.excel?.rows || 20}
                            onChange={(event) => handleSubConfigChange(event, 'mbox', 'excel')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-2 mb-4">
                        <label className="text-gray-700 w-22">Column headings:</label>
                        <input
                            name="columns"
                            value={configurations.mbox?.excel?.columns}
                            onChange={(event) => handleSubConfigChange(event, 'mbox', 'excel')}
                            className="form-input w-96 h-10 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-4 mb-4">
                        <label className="text-gray-700 w-22">Description:</label>
                        <input
                            name="description"
                            value={configurations.mbox?.excel?.description}
                            onChange={(event) => handleSubConfigChange(event, 'mbox', 'excel')}
                            className="form-input w-96 h-10 border border-gray-300 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center space-x-8 mb-4">
                        <label className="text-gray-700 w-22">Files num:</label>
                        <input
                            type="number"
                            name="file_nums"
                            value={configurations.mbox?.excel?.file_nums || 0}
                            onChange={(event) => handleSubConfigChange(event, 'mbox', 'excel')}
                            className="form-input w-24 border border-gray-300 focus:border-blue-500"
                            max="2"
                            min="0"
                        />
                    </div>
                </div>
            );
        };

        function getCurrentDate() {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }

        return (
            <div className="flex flex-col space-y-4">
                <div className="flex items-center space-x-4">
                    <label className="text-gray-700 w-22">n_messages_in_inbox:</label>
                    <input
                        type="number"
                        name="n_messages_in_inbox"
                        value={configurations.mbox?.n_messages_in_inbox || '1'}
                        onChange={(event) => handleConfigChange(event, 'mbox')}
                        className="px-2 py-1 form-input w-24 border border-gray-300 focus:border-blue-500 focus:outline-none"
                    />
                </div>

                <div className="flex items-center space-x-4">
                    <label className="text-gray-700 w-22">n_messages_in_sent:</label>
                    <input
                        type="number"
                        name="n_messages_in_sent"
                        value={configurations.mbox?.n_messages_in_sent || '1'}
                        onChange={(event) => handleConfigChange(event, 'mbox')}
                        className="px-2 py-1 form-input w-24 border border-gray-300 focus:border-blue-500 focus:outline-none"
                    />
                </div>

                <div className="flex items-center space-x-4">
                    <label className="text-gray-700 w-22">Description:</label>
                    <textarea
                        name="description"
                        value={configurations.mbox?.description || ''}
                        onChange={(event) => handleConfigChange(event, 'mbox')}
                        className="px-2 py-1 form-textarea w-96 h-20 border border-gray-300 focus:border-blue-500 focus:outline-none"
                    />
                </div>

                <div className="flex items-center space-x-4">
                    <label className="text-gray-700 w-22">Time Range:</label>
                    <div className="flex space-x-4">
                        <input
                            type="date"
                            name="start"
                            value={configurations.mbox?.start || ''}
                            onChange={(event) => handleConfigChange(event, 'mbox')}
                            className="px-2 py-1 form-input w-56 border border-gray-300 focus:border-blue-500 focus:outline-none"
                            max={getCurrentDate()}
                        />

                        <input
                            type="date"
                            name="end"
                            value={configurations.mbox?.end || ''}
                            onChange={(event) => handleConfigChange(event, 'mbox')}
                            className="px-2 py-1 form-input w-56 border border-gray-300 focus:border-blue-500 focus:outline-none"
                            max={getCurrentDate()}
                        />
                    </div>
                </div>

                <div className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        name="enable_cc"
                        checked={configurations.mbox?.enable_cc || false}
                        onChange={(event) => {
                            const {checked} = event.target;
                            handleConfigChange({target: {name: 'enable_cc', value: checked}}, 'mbox');
                        }}
                        className="form-checkbox h-4 w-4 text-indigo-600"
                    />
                    <label className="text-gray-700">Enable Multiple Emails in CC</label>
                </div>

                <div className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        name="enable_to"
                        checked={configurations.mbox?.enable_to || false}
                        onChange={(event) => {
                            const {checked} = event.target;
                            handleConfigChange({target: {name: 'enable_to', value: checked}}, 'mbox');
                        }}
                        className="form-checkbox h-4 w-4 text-indigo-600"
                    />
                    <label className="text-gray-700">Enable Multiple Emails in To</label>
                </div>

                <div className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        name="attachments"
                        checked={configurations.mbox?.enable_attachments || false}
                        onChange={(event) => {
                            const {checked} = event.target;
                            handleConfigChange({target: {name: 'enable_attachments', value: checked}}, 'mbox');
                        }}
                        className="form-checkbox h-4 w-4 text-indigo-600"
                    />
                    <label className="text-gray-700">Attachments</label>
                </div>

                {configurations.mbox?.enable_attachments && (
                    <div className="flex flex-col space-y-4 mt-4">
                        <div className="border border-gray-300 rounded p-4">
                            <h3 className="text-lg font-semibold">Docx</h3>
                            {renderDocxAttachConfigurations()}
                        </div>

                        <div className="border border-gray-300 rounded p-4">
                            <h3 className="text-lg font-semibold">PDF</h3>
                            {renderPdfAttachConfigurations()}
                        </div>

                        <div className="border border-gray-300 rounded p-4">
                            <h3 className="text-lg font-semibold">Excel</h3>
                            {renderExcelAttachConfigurations()}
                        </div>
                    </div>
                )}

            </div>
        );
    };

    const handleDownload = () => {
        setIsDownload(false)
    }

    const handleGenerate = () => {
        // 添加缺失的配置项，并设置默认值

        setIsLoading(true);

        handleSave();

        let updatedConfigurations = {...configurations};

        if (selectedFormats.includes('docx')) {
            updatedConfigurations = {
                ...updatedConfigurations,
                docx: {
                    ...updatedConfigurations.docx,
                    type: 'docx',
                    words: updatedConfigurations.docx?.words || 200,
                    paragraphs: updatedConfigurations.docx?.paragraphs || 1,
                    description: updatedConfigurations.docx?.description || '',
                    file_nums: updatedConfigurations.docx?.file_nums || 1
                },
            };
        }


        if (selectedFormats.includes('pdf')) {
            updatedConfigurations = {
                ...updatedConfigurations,
                pdf: {
                    ...updatedConfigurations.pdf,
                    type: 'pdf',
                    words: updatedConfigurations.pdf?.words || 200,
                    paragraphs: updatedConfigurations.pdf?.paragraphs || 1,
                    description: updatedConfigurations.pdf?.description || '',
                    file_nums: updatedConfigurations.pdf?.file_nums || 1
                },
            };
        }


        if (selectedFormats.includes('excel')) {
            updatedConfigurations = {
                ...updatedConfigurations,
                excel: {
                    ...updatedConfigurations.excel,
                    type: 'excel',
                    rows: configurations.excel?.rows || 20,
                    columns: configurations.excel?.columns || '',
                    description: configurations.excel?.description || '',
                    file_nums: updatedConfigurations.excel?.file_nums || 1
                },
            };
        }

        if (selectedFormats.includes('pst')) {
            updatedConfigurations = {
                ...updatedConfigurations,
                pst: {
                    ...updatedConfigurations.pst,
                    type: 'pst',
                    n_messages_in_inbox: updatedConfigurations.pst?.n_messages_in_inbox || 1,
                    n_messages_in_sent: updatedConfigurations.pst?.n_messages_in_sent || 1,
                    description: updatedConfigurations.pst?.description || '',
                    start: updatedConfigurations.pst?.start || '2020-01-01',
                    end: updatedConfigurations.pst?.end || '2020-01-03',
                    enable_cc: updatedConfigurations.pst?.enable_cc || false,
                    enable_to: updatedConfigurations.pst?.enable_to || false
                },
            };

            if (updatedConfigurations.pst.enable_attachments === true) {
                updatedConfigurations.pst.attachments = [];

                if (updatedConfigurations.pst.docx !== undefined) {
                    const attach_docx = {
                        ...updatedConfigurations.pst.docx,
                        type: 'docx',
                        words: updatedConfigurations.pst.docx?.words || 200,
                        paragraphs: updatedConfigurations.pst.docx?.paragraphs || 1,
                        description: updatedConfigurations.pst.docx?.description || '',
                        file_nums: updatedConfigurations.pst.docx?.file_nums || 0
                    };

                    updatedConfigurations.pst.attachments.push(attach_docx);
                }

                if (updatedConfigurations.pst.pdf !== undefined) {
                    const attach_pdf = {
                        ...updatedConfigurations.pst.pdf,
                        type: 'pdf',
                        words: updatedConfigurations.pst.pdf?.words || 200,
                        paragraphs: updatedConfigurations.pst.pdf?.paragraphs || 1,
                        description: updatedConfigurations.pst.pdf?.description || '',
                        file_nums: updatedConfigurations.pst.pdf?.file_nums || 0
                    };

                    updatedConfigurations.pst.attachments.push(attach_pdf);
                }

                if (updatedConfigurations.pst.excel !== undefined) {
                    const attach_excel = {
                        ...updatedConfigurations.pst.excel,
                        type: 'excel',
                        rows: configurations.pst.excel?.rows || 20,
                        columns: configurations.pst.excel?.columns || '',
                        description: configurations.pst.excel?.description || '',
                        file_nums: updatedConfigurations.pst.excel?.file_nums || 0
                    };

                    updatedConfigurations.pst.attachments.push(attach_excel);
                }
            }
        }


        if (selectedFormats.includes('mbox')) {
            updatedConfigurations = {
                ...updatedConfigurations,
                mbox: {
                    ...updatedConfigurations.mbox,
                    type: 'mbox',
                    n_messages_in_inbox: updatedConfigurations.mbox?.n_messages_in_inbox || 1,
                    n_messages_in_sent: updatedConfigurations.mbox?.n_messages_in_sent || 1,
                    description: updatedConfigurations.mbox?.description || '',
                    start: updatedConfigurations.mbox?.start || '2020-01-01',
                    end: updatedConfigurations.mbox?.end || '2020-01-03',
                    enable_cc: updatedConfigurations.mbox?.enable_cc || false,
                    enable_to: updatedConfigurations.mbox?.enable_to || false
                },
            };

            if (updatedConfigurations.mbox.enable_attachments === true) {
                updatedConfigurations.mbox.attachments = [];

                if (updatedConfigurations.mbox.docx !== undefined) {
                    const attach_docx = {
                        ...updatedConfigurations.mbox.docx,
                        type: 'docx',
                        words: updatedConfigurations.mbox.docx?.words || 200,
                        paragraphs: updatedConfigurations.mbox.docx?.paragraphs || 1,
                        description: updatedConfigurations.mbox.docx?.description || '',
                        file_nums: updatedConfigurations.mbox.docx?.file_nums || 0
                    };

                    updatedConfigurations.mbox.attachments.push(attach_docx);
                }

                if (updatedConfigurations.mbox.pdf !== undefined) {
                    const attach_pdf = {
                        ...updatedConfigurations.mbox.pdf,
                        type: 'pdf',
                        words: updatedConfigurations.mbox.pdf?.words || 200,
                        paragraphs: updatedConfigurations.mbox.pdf?.paragraphs || 1,
                        description: updatedConfigurations.mbox.pdf?.description || '',
                        file_nums: updatedConfigurations.mbox.pdf?.file_nums || 0
                    };

                    updatedConfigurations.mbox.attachments.push(attach_pdf);
                }

                if (updatedConfigurations.mbox.excel !== undefined) {
                    const attach_excel = {
                        ...updatedConfigurations.mbox.excel,
                        type: 'excel',
                        rows: configurations.mbox.excel?.rows || 20,
                        columns: configurations.mbox.excel?.columns || '',
                        description: configurations.mbox.excel?.description || '',
                        file_nums: updatedConfigurations.mbox.excel?.file_nums || 0
                    };

                    updatedConfigurations.mbox.attachments.push(attach_excel);
                }
            }
        }

        let parmas = {};

        if (email !== '' && email !== undefined && email !== null ) {
            parmas.email = email;
        }

        parmas.data = Object.values(updatedConfigurations).filter(obj => obj.hasOwnProperty("type"));

        const endpointUrl = process.env.REACT_APP_ENDPOINT_URL + "/generate";

        postFunc("/generate", parmas, (data) => {
            if (data.ret === 200) {
                setDownloadUrl(data.url);
                setIsLoading(false);
            } else {
                /* balance is not enough */
                if (data.ret === 402 ) {
                    alert(data.msg);
                } else {
                    alert(data.err);
                }
                setIsLoading(false);
            }
        }, (error) => {alert(error)}, () => {
            setIsDownload(true)
        }, true);
    };

    return (
        <div className="relative bg-orange-50 pl-64 p-4 h-screen">
            <div className="container mx-auto">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-2xl font-semibold text-orange-500">Select Output Formats:</h2>
                </div>

                <div className="bg-white rounded shadow-md p-4 mb-4">{renderFormatOptions()}</div>

                {selectedFormats.length > 0 && (
                    <div className="flex flex-col space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            {selectedFormats.includes('docx') && (
                                <div className="bg-white rounded shadow-md p-4">
                                    <h2 className="text-2xl font-semibold mb-4 text-orange-500">Docx</h2>
                                    {renderDocxConfigurations()}
                                </div>
                            )}
                            {selectedFormats.includes('pdf') && (
                                <div className="bg-white rounded shadow-md p-4">
                                    <h2 className="text-2xl font-semibold mb-4 text-orange-500">PDF</h2>
                                    {renderPdfConfigurations()}
                                </div>
                            )}
                            {selectedFormats.includes('excel') && (
                                <div className="bg-white rounded shadow-md p-4">
                                    <h2 className="text-2xl font-semibold mb-4 text-orange-500">Excel</h2>
                                    {renderExcelConfigurations()}
                                </div>
                            )}
                            <div className="flex space-x-4">
                            {selectedFormats.includes('pst') && (
                                <div className="bg-white rounded shadow-md p-4">
                                    <h2 className="text-2xl font-semibold mb-4 text-orange-500">PST</h2>
                                    {renderPstConfigurations()}
                                    </div>
                                )}
                                {selectedFormats.includes('mbox') && (
                                    <div className="bg-white rounded shadow-md p-4">
                                        <h2 className="text-2xl font-semibold mb-4 text-orange-500">Mbox</h2>
                                        {renderMboxConfigurations()}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex space-x-4 mt-4">
                    <button
                        onClick={handleGenerate}
                        className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Synfakeit!
                    </button>

                    {isDownload && downloadUrl && (
                        <a href={downloadUrl} download>
                            <button
                                onClick={handleDownload}
                                className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
                            >
                                Download
                            </button>
                        </a>
                    )}
                </div>

                {isLoading && (
                    <div
                        className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex items-center justify-center">
                        <div className="bg-white p-4 rounded shadow">
                            <div className="flex items-center space-x-4 mb-4">
                                <span className="text-xl font-semibold">Synfaking your data...</span>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default ConfigPage;
