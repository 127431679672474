import React, {useEffect, useState} from 'react';
import {PublicClientApplication} from '@azure/msal-browser';
import {getFunc, postFunc} from "../util/httpclient";
import {FaMicrosoft} from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';
import {useCookies} from "react-cookie";
import {Spinner} from './spinner';

const MicrosoftLogin = () => {
    const [msalInstance, setMsalInstance] = useState(null);
    const [clientId, setClientId] = useState("");
    const [callBackUrl, setCallBackUrl] = useState("");
    const [cookies, setCookie] = useCookies(['isLoggedIn']);
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        getFunc("/auth/microsoft", (data) => {
            if (data.ret != 200) {
                alert(`get microsoft client id failed, ${data.err}`);
            } else {
                setClientId(data.client_id);
                setCallBackUrl(data.url);

                const msalConfig = {
                    auth: {
                        clientId: clientId,
                        redirectUri: callBackUrl,
                    },
                    cache: {
                        cacheLocation: 'localStorage',
                        storeAuthStateInCookie: false,
                    },
                };

                const msalObj = new PublicClientApplication(msalConfig);
                setMsalInstance(msalObj);
            }
        }, (error) => {
            console.log(error);
            alert("get microsoft client id failed")
        }, () => {
        }, false)
    }, [clientId]);


    const onSuccess = (response) => {
        const JwtToken = response.idToken;

        postFunc("/auth/microsoft/callback", {token: JwtToken}, (data) => {
            if (data.ret == 200) {
                localStorage.setItem("token", data.token);
                localStorage.setItem("user_id", data.user_id);
                localStorage.setItem("role", data.role);
                localStorage.setItem("username", data.username);
                setCookie('isLoggedIn', true, {path: '/'});
                navigate("/");
            } else {
                alert(data.err)
            }
        }, (err) => {
            console.log(err);
            alert("microsoft auth failed")
        }, () =>{}, false)
    };

    // Microsoft 登录失败的回调
    const onFailure = (error) => {
        console.error(error); // 处理登录失败的逻辑
        alert("microsoft auth failed");
    };

    const handleLogin = async () => {
        setIsLoading(true);  // 设置 isLoading 为 true，表示开始加载

        try {
            const loginRequest = {
                scopes: ['openid', 'profile', 'User.Read'],
            };

            const loginResponse = await msalInstance.loginPopup(loginRequest);
            onSuccess(loginResponse);
        } catch (error) {
            onFailure(error);
        }
    };

    return (
        <button
            onClick={handleLogin}
            className="bg-orange-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded mr-2 flex items-center">
            <FaMicrosoft className="mr-2"/>
            Login with Microsoft
        </button>
    );
};

export default MicrosoftLogin;
